import 'regenerator-runtime/runtime'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, addDoc , collection} from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyBY3t4vjuzIXdRybe5nsbn6erBRWu0Eb78",
  authDomain: "salesflyer-io.firebaseapp.com",
  projectId: "salesflyer-io",
  storageBucket: "salesflyer-io.appspot.com",
  messagingSenderId: "865919545032",
  appId: "1:865919545032:web:323aa5f7711d6d955b3ffd",
  measurementId: "G-NZ9S8KE832"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
  

// TODO: Log event 

var emailFilter = /^([a-zA-Z0-9_\.\-\'])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
var freeDomainFilter = /^([\w\.\-]+)@(?:gmail|yahoo|live|hotmail|outlook|msn|qq|aol|me|icloud|rediffmail|comcast|ymail|email|bugcrowdninja|yopmail)(\.[.\w-]+)?\.([a-zA-Z-]{2,})$/i;


var heroEmail = document.getElementById('hero-email')
var heroSubmit = document.getElementById('hero-submit')

var footerEmail = document.getElementById('footer-email')
var footerSubmit = document.getElementById('footer-submit')

var awesomeBtn = document.getElementById('awesome-btn')
var awsomeClose = document.getElementById('awesome-close')
var successModal = document.getElementById('success-modal')


function handleModalClose(e){
    e.preventDefault();
    successModal.classList.add('hidden')
}

awesomeBtn.addEventListener('click', handleModalClose)
awsomeClose.addEventListener('click', handleModalClose)



heroEmail.addEventListener('focus', function(e) {
    e.currentTarget.value = ''
    removeError('hero-error')
    heroEmail.classList.remove('animate__animated', 'animate__shakeX')
})
footerEmail.addEventListener('focus', function(e) {
    e.currentTarget.value = ''
    removeError('footer-error')
    footerEmail.classList.remove('animate__animated', 'animate__shakeX')
})

heroSubmit.addEventListener('click', function(e) {
    e.preventDefault();
    const email = heroEmail.value
    if(validateEmail(email, 'hero-error')){
       sendDataToFireStore(heroEmail.value, 'hero-error')
    } else {
        heroEmail.classList.add('animate__animated', 'animate__shakeX')
    }
})

footerSubmit.addEventListener('click', function(e) {
    e.preventDefault();
    const email = footerEmail.value
    if(validateEmail(email, 'footer-error')){
       sendDataToFireStore(footerEmail.value, 'footer-error')
    } else {
        footerEmail.classList.add('animate__animated', 'animate__shakeX')
    }
})



async function sendDataToFireStore(email, elementId){
    var element = document.getElementById(elementId);
    try {
        const docRef = await addDoc(collection(db, "users"), {
            email: email
        });
        successModal.classList.remove('hidden')
      } catch (e) {
        console.error("Error adding document: ", e);
        element.innerText = "Something went wrong, please try again!"
        element.classList.add('error')
        element.classList.remove('hidden')
        resetMessageBox(element)
      }
}

function resetMessageBoxWithDelay(element){
    setTimeout(function(){
        resetMessageBox(element)
    }, 2000)
}

function resetMessageBox(element){
    element.innerText = ""
    element.classList.add('hidden')
    element.classList.remove('error')
    element.classList.remove('success')
}

function removeError(elementId){
    var element = document.getElementById(elementId);
    resetMessageBox(element)
}


function validateEmail(email, elementId) {
    var element = document.getElementById(elementId);
    if(email.length <= 0){
        element.innerText = "Enter a valid Email Address"
        element.classList.add('error')
        element.classList.remove('hidden')
        return false;
    }
    else if (email.length > 0 && !emailFilter.test(email)) {
        element.innerText = "Invalid Email Address"
        element.classList.add('error')
        element.classList.remove('hidden')
        return false;
    } else if (email.length > 0 && freeDomainFilter.test(email)) {
        element.innerText = "Please enter your work email"
        element.classList.add('error')
        element.classList.remove('hidden')
        return false;
    } 
    return true;
}